@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,100&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@media screen and (max-width: 500.7px) {

  body .uwy .uai,
  .uwy .uai img:not(.check_on),
  body .uwy .uai img:not(.check_on) {
    width: 30px !important;
    height: 30px !important;
    min-width: 30px !important;
    min-height: 30px !important;
    max-width: 30px !important;
    max-height: 30px !important;
    padding: 2px !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 335.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 25px !important;
    left: 245px !important;
  }
}

@media only screen and (min-width: 336px) and (max-width: 350.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 25px !important;
    left: 260px !important;
  }
}

@media only screen and (min-width: 351px) and (max-width: 374.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 25px !important;
    left: 280px !important;
  }
}

@media only screen and (min-width: 375px) and (max-width: 424.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 30px !important;
    left: 295px !important;
  }
}

@media only screen and (min-width: 425px) and (max-width: 500.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 32px !important;
    left: 340px !important;
  }
}

@media only screen and (min-width: 501px) and (max-width: 600.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 30px !important;
    left: 405px !important;
  }
}

@media only screen and (min-width: 601px) and (max-width: 660.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 30px !important;
    left: 450px !important;
  }
}

@media only screen and (min-width: 661px) and (max-width: 710.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 30px !important;
    left: 490px !important;
  }
}

@media only screen and (min-width: 711px) and (max-width: 740.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 30px !important;
    left: 530px !important;
  }
}

@media only screen and (min-width: 741px) and (max-width: 767.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 30px !important;
    left: 550px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 820.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 35px !important;
    left: 580px !important;
  }
}

@media only screen and (min-width: 821px) and (max-width: 900.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 10px !important;
    left: 590px !important;
  }
}

@media only screen and (min-width: 901px) and (max-width: 960.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 10px !important;
    left: 600px !important;
  }
}

@media only screen and (min-width: 961px) and (max-width: 991.7px) {
  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai, 
  body .uwy.userway_p8 .uai {
    top: 10px !important;
    left: 610px !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1007.7px) {

  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai,
  body .uwy.userway_p8 .uai {
    top: 20px !important;
    left: 430px !important;
  }
}

@media only screen and (min-width: 1008px) and (max-width: 1023.7px) {

  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai,
  body .uwy.userway_p8 .uai {
    top: 20px !important;
    left: 440px !important;
  }
}



/* if device has a touch screen */
@media (any-pointer: coarse) {
  @media only screen and (min-width: 1024px) and (max-width: 1035.7px) {
    body .uwy.userway_p3 .uai {
      top: 20px !important;
      left: 450px !important;
    }
  }
}

/* if device has no touch screen */
@media (any-pointer: fine) {
  @media only screen and (min-width: 1024px) and (max-width: 1035.7px) {
    body .uwy.userway_p3 .uai {
      top: 20px !important;
      left: 450px !important;
    }
  }
}

@media only screen and (min-width: 1036px) and (max-width: 1099.7px) {

  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai,
  body .uwy.userway_p8 .uai {
    top: 20px !important;
    left: 500px !important;
  }
}

@media only screen and (min-width: 1100px) and (max-width: 1129.7px) {

  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai,
  body .uwy.userway_p8 .uai {
    top: 20px !important;
    left: 520px !important;
  }
}

@media only screen and (min-width: 1130px) and (max-width: 1399.7px){

  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai,
  body .uwy.userway_p8 .uai {
    top: 20px !important;
    left: 520px !important;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1700.7px){

  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai,
  body .uwy.userway_p8 .uai {
    top: 20px !important;
    left: 600px !important;
  }
}


@media only screen and (min-width: 1701px) and (max-width: 2099.7px){

  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai,
  body .uwy.userway_p8 .uai {
    top: 20px !important;
    left: 800px !important;
  }
}

@media only screen and (min-width: 2100px) and (max-width: 2560.7px){

  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai,
  body .uwy.userway_p8 .uai {
    top: 20px !important;
    left: 1050px !important;
  }
}

@media only screen and (min-width: 2561px) {

  body .uwy.userway_p3 .uai,
  body .uwy.userway_p7 .uai,
  body .uwy.userway_p8 .uai {
    top: 20px !important;
    left: 1400px !important;
  }
}