body {
  font-family: "Poppins" !important;
  margin: 0 !important;
  padding: 0 !important;
}

a:hover {
  text-decoration: underline;
}

.navbar-toggler-icon {
  width: 2.5em;
  height: 2.5em;
}

.header-d {
  margin-top: 7rem;
}

@media (min-width: 1024px) and (max-width: 1400.7px) {
  .header-d {
    margin-top: 8rem;
  }
}

@media (max-width: 991.98px) {
  .sticky-top {
    margin-top: -5.5rem !important;
  }
}

.cyanButton::before {
  content: "Click here for ";
}

@media only screen and (min-width: 320px) and (max-width: 369.7px) {
  .cyanButton {
    font-size: 0.7rem !important;
  }

  .formHeadingPara {
    font-size: 13px !important;
  }
}

@media only screen and (min-width: 370px) and (max-width: 768.7px) {
  .cyanButton {
    font-size: 0.9rem;
  }

  .formHeadingPara {
    font-size: 17px !important;
  }
}

@media (min-width: 320px) and (max-width: 575.7px) {
  .FooterNavMenuLI {
    font-size: 0.57rem !important;
  }
}

.applynow {
  background-color: #083F88 !important;
  border-color: #ffff !important;
}

@media (min-width: 320px) and (max-width: 767.7px) {
  .disclaimertextrefinance {
    padding: 10px 5px !important;
    font-size: 0.75rem;
  }
}

@media (min-width: 768px) and (max-width: 991.7px) {
  .disclaimertextrefinance {
    font-size: 0.75rem;
  }
}

@media (min-width: 320px) and (max-width: 767.7px) {
  .disclaimertextwhyvaloan {
    font-size: 0.70rem !important;
  }
}

@media (min-width: 768px) and (max-width: 991.7px) {
  .disclaimertextwhyvaloan {
    font-size: 0.70rem !important;
  }
}

@media (min-width: 320px) and (max-width: 575.7px) {
  .applyNowWrapper .btn-submit {
    background-color: #2ba8df;
    border-radius: 25px !important;
    padding: 7px 15px !important;
    font-size: 14px;
    border: 1px solid #2ba8df;
  }
}

@media (min-width: 1200px) and (max-width: 1400.7px) {
  .ratesTablenew {
    width: 85% !important;
  }
}